<template>
  <div v-if="contest">
    <v-card
      v-if="contest"
      class="contest-big-card"
      :dark="$vuetify.breakpoint.xsOnly"
      :class="$vuetify.breakpoint.xsOnly ? 'mobile' : ''"
      :elevation="$vuetify.breakpoint.xsOnly ? '0' : '1'"
    >
      <div class="cover" :style="{ backgroundImage: 'url(' + contest.image_big + ')' }"></div>

      <div class="contest-long">
        <div v-if="$vuetify.breakpoint.smAndUp" class="contest-image">
          <img v-if="contest.image_big" :src="contest.image_big" />
        </div>
        <div class="contest-long-body">
          <div class="contest-title">
            {{ contest.title }}
            <div class="title-info">
              <div class="contest-status">{{ contest.text_status }}</div>
              <div class="books-count">{{ +contest.count_books }} книг</div>
            </div>
          </div>
          <v-card
            outlined
            class="card-prize"
            :class="$vuetify.breakpoint.xsOnly ? 'mt-4' : 'mt-auto'"
          >
            <div class="prize">
              <div class="head" v-if="contest.status < 4">
                <v-icon
                  :color="$vuetify.breakpoint.smAndUp ? 'primary' : ''"
                >$vuetify.icons.trophy_award</v-icon>Приз
              </div>
              <div v-html="prizeText" class="body"></div>
            </div>
          </v-card>
        </div>
      </div>
      <v-card-actions class="mt-auto pl-3 pr-3 pb-3 pt-0">
        <v-btn
          v-if="$route.name == 'ContestShow' && showRulesButton"
          dark
          color="primary"
          :to="{name: 'ContestRules', params: {slug: contest.slug}}"
        >Правила конкурса</v-btn>
        <v-btn
          v-if="$route.name == 'ContestRules'"
          dark
          color="primary"
          :to="{name: 'ContestShow', params: {slug: contest.slug}}"
        >Назад</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
        <v-btn
          v-if="$vuetify.breakpoint.smAndUp && welcomeBooks"
          @click="addBook()"
          color="primary"
          dark
        >Участвовать</v-btn>
      </v-card-actions>
    </v-card>

    <v-btn
      v-if="$vuetify.breakpoint.xsOnly && welcomeBooks"
      @click="addBook()"
      color="primary"
      dark
      block
    >Участвовать</v-btn>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({}),
  methods: {
    addBook() {
      if (!this.currentUser) {
        this.$root.showAuthMessage();
        return;
      }

      this.$router.push({
        name: "ContestAdd",
        params: { slug: this.contest.slug },
      });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      books: "contest/books",
      contest: "contest/contest",
    }),
    prizeText(){
      if(this.contest){
        if(this.contest.status == 4 && this.contest.winners_body){
          return this.contest.winners_body;
        }

        return this.contest.prize;
      }
      return '';
    },
    welcomeBooks(){
      //Принмаем заявки
      if(this.contest && this.contest.status == 2 ){
         return true;
      }
      return false;
    },
    showRulesButton(){
      //Показываем ссылку на правила
      if(this.contest && this.contest.status < 3 ){
         return true;
      }
      return false;
    }
  },
  created() {},
};
</script>
