<template>
  <div>
    <v-toolbar tile flat :elevation="$vuetify.breakpoint.xsOnly ? '0' : '1'">
      <v-btn :to="{name: 'ContestAll'}" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ contestTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <!-- <v-container v-if="!loadPage"> -->
    <v-container>
      <contest-big-card></contest-big-card>
      <contest-details></contest-details>
      <v-card class="mt-4">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Книги</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu transition="slide-y-transition" left bottom offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text tile v-on="on">
                <span class="d-none d-sm-flex mr-1">Сортировка</span>
                <v-icon>$vuetify.icons.sort</v-icon>
              </v-btn>
            </template>
            <v-list class="text-right">
              <v-list-item-group color="primary">
                <!-- class="active v-list-item--active" -->
                <v-list-item
                  v-for="(item, index) in sortings"
                  :key="index"
                  :class="item.val === sort ? 'active v-list-item--active' : ''"
                  @click="sortCategory(item.val)"
                >
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-toolbar>

        <no-content v-if="books.length == 0" route-name title="Произведений нет"></no-content>
        <book-card v-for="(book, index) in books" :key="book.id" :book="book" :index="index"></book-card>
        <v-col cols="12">
          <v-pagination
            v-if="pages > 1"
            @input="goPage"
            v-model="page"
            :length="pages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BookCard from "../book/BookCard.vue";
import ContestBigCard from "./ContestBigCard.vue";
import ContestDetails from "./ContestDetails.vue";
export default {
  components: {
    BookCard,
    ContestBigCard,
    ContestDetails
  },
  data: () => ({
    slug: "",
    sort: "new",
    sortings: [
      { text: "По новизне", val: "new" },
      { text: "По популярности", val: "popular" },
      { text: "По комментариям", val: "comments" },
    ],
    page: 1,
    pages: 0,
    loading: false,
  }),
  methods: {
    getContest() {
      var app = this;

      this.$store
        .dispatch("contest/getContest", {
          slug: app.slug,
          page: app.page,
          sort: app.sort,
        })
        .then((response) => {
          // app.loading = false;
          if (response.data.success == 1) {
            app.pages = response.data.books.last_page;
          }
        });
    },
    goPage(nextPage) {
      // console.log('nextPage',nextPage);
      //   console.log(this.$route.query.page);
      if (nextPage != this.$route.query.page) {
        this.$router.push({
          name: this.$route.name,
          params: { slug: this.slug },
          query: { page: nextPage, sort: this.sort },
        });
      }
    },
    sortCategory(type) {
      if (type != this.sort) {
        this.sort = type;
        this.$router.push({
          name: this.$route.name,
          params: { slug: this.slug },
          query: { page: this.page, sort: this.sort },
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      books: "contest/books",
      contest: "contest/contest",
    }),
    contestTitle() {
      if (this.contest && this.contest.title) {
        return this.contest.title;
      }
      return "Конкурс";
    },
    categories() {
      if (!this.contest || !this.contest.categories_show) {
        return [];
      }
      let categories = this.contest.categories_show.map(function (category) {
        category.text = category.name;
        category.to = { name: "CategoryShow", params: { slug: category.slug } };
        return category;
      });

      return categories;
    },
  },
  created() {
    //console.log(this.$route.query.page);
    this.slug = this.$route.params.slug ? this.$route.params.slug : null;
    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.sort = this.$route.query.sort ? this.$route.query.sort : "new";

    this.getContest();
  },
};
</script>
